import React from "react";

const Footer = () => {
  return (
    <div
      className="col-md-12 col-sm-12 text-center"
      style={{ color: "#999", fontSize: 8 , marginBottom:"-15px" }}
    >
      Copyright © {new Date().getFullYear()} GYB All rights reserved.
    </div>
  );
};

export default Footer;
