import useStore from "../../store";
import PuffLoader from "react-spinners/PuffLoader";
import { useScrollLock } from "./../../utils/scrollLock/scrollLock";

const Loader = () => {
  const loader = useStore.loaderStore((state) => state.loader);
  const { lockScroll, unlockScroll } = useScrollLock();

  loader ? lockScroll() : unlockScroll();
  return (
    <>
      {loader ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: -5,
            width: "100%",
            height: "105%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999999,
          }}
          className="tw-bg-gray-800 tw-bg-opacity-75 tw-transition-opacity"
        >
          <PuffLoader color="#fff" />
        </div>
      ) : null}
    </>
  );
};

export default Loader;
