import { Navigate } from "react-router-dom";
import userStore from "../store/stores/auth";

const PublicRoute = ({ children }) => {
  const auth = userStore((state) => state.auth);

  if (auth.isAuthenticated && auth.token) {
    return <Navigate to="/Patient Report/report/page/1" replace></Navigate>;
  }

  return children;
};

export default PublicRoute;
