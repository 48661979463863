import create from "zustand";
import { devtools } from "zustand/middleware";

const activePageStore = create(
  devtools((set) => ({
    activePage: "",
    setActivePage: (activePage) => set({ activePage }),
  }))
);

export default activePageStore;
