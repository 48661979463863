import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notificationService } from "../../services/notificationService";

const Notification = () => {
  useEffect(() => {
    const subscribe = notificationService.getMessage().subscribe((message) => {
      if (message.type === "danger") {
        toast.error(message.message);
      } else {
        toast.success(message.message);
      }
    });

    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      subscribe.unsubscribe();
    };
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default Notification;
