import { Subject } from "rxjs";

const subject = new Subject();

export const notificationService = {
  getMessage: () => subject.asObservable(),
  sendErrorMessage: (message) =>
    subject.next({ message: message, type: "danger" }),
  sendInfoMessage: (message) =>
    subject.next({ message: message, type: "info" }),
  sendSuccessMessage: (message) =>
    subject.next({ message: message, type:"success" }),
  clearMessages: () => subject.next(),
};
