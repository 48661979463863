import { Navigate } from "react-router-dom";
import * as constant from "../utils/constant";

import Store from "../store/stores/auth";
// import { useSelector } from "react-redux";
import { getLocalStorage } from "./../services/storage";

const ProtectedRoute = ({ children }) => {
  const profile = getLocalStorage(constant.STORAGE_PROFILE_KEY);
  const token = getLocalStorage(constant.STORAGE_TOKEN_KEY);
  const auth = Store((state) => state.auth);

  // const auth = useSelector((state) => state.auth);

  if (!auth.isAuthenticated && profile === null && token === null) {
    return <Navigate to="/login" replace></Navigate>;
  }

  return children;
};

export default ProtectedRoute;
