import create from "zustand";
import { devtools } from "zustand/middleware";

const tablePageLength = create(
  devtools((set) => ({
    pageLength: 0,
    setPageLength: (pageLength) => set({ pageLength }),
  }))
);

export default tablePageLength;
