import { postWithoutTOken } from "./apiUtils";
import { notificationService } from "./../notificationService";
import * as keys from "../../utils/constant";
import * as storage from "../storage";
import * as constant from "../../utils/constant";

export const logout = () => {
  storage.deleteLocalStorage(constant.STORAGE_PROFILE_KEY);
  storage.deleteLocalStorage("pageName");
  storage.deleteLocalStorage(keys.COMMON_SERVICE_AUTH_TOKEN);
  storage.deleteLocalStorage(keys.STORAGE_TOKEN_KEY);
};

export const login = async (data) => {
  return await postWithoutTOken(
    process.env.REACT_APP_API_URL + "User/Login/",
    "",
    data,
    false,
    false
  )
    .then((response) => {
      const token = response.receiveObj.data.data.token;
      const user = response.receiveObj.data.data;
      const commonServiceToken =
        response.receiveObj.data.data?.commonServiceToken;
      if (response.success && token && user) {
        storage.setLocalStorage(keys.STORAGE_TOKEN_KEY, token);
        storage.setLocalStorage(
          keys.COMMON_SERVICE_AUTH_TOKEN,
          commonServiceToken
        );
        storage.setLocalStorage(keys.STORAGE_PROFILE_KEY, user);
        return {
          isAuthenticated: true,
          token: token,
          profile: user,
          commonServiceToken: commonServiceToken,
        };
      }
    })
    .catch((error) => {
      storage.deleteLocalStorage(keys.COMMON_SERVICE_AUTH_TOKEN);
      storage.deleteLocalStorage(keys.STORAGE_TOKEN_KEY);
      storage.deleteLocalStorage(keys.STORAGE_PROFILE_KEY);
      const errorsArray = error?.receiveObj?.response?.data?.errors;
      if (errorsArray !== null && errorsArray?.Name) {
        notificationService.sendErrorMessage(errorsArray.Name[0]);
      } else if (errorsArray !== null && errorsArray?.Password) {
        notificationService.sendErrorMessage(errorsArray.Password[0]);
      } else {
        const errorMessage = error?.receiveObj?.response?.data?.message;
        if (errorMessage) {
          notificationService.sendErrorMessage(errorMessage);
        }
      }
      return error;
    });
};

export const redirectedLogin = async (data) => {
  return await postWithoutTOken(
    process.env.REACT_APP_API_URL + "User/GetUserProfile/",
    "",
    data,
    false,
    false
  )
    .then((response) => {
      const token = response.receiveObj.data.data.token;
      const user = response.receiveObj.data.data;
      const commonServiceToken =
        response.receiveObj.data.data?.commonServiceToken;
      if (response.success && token && user) {
        storage.setLocalStorage(keys.STORAGE_TOKEN_KEY, token);
        storage.setLocalStorage(
          keys.COMMON_SERVICE_AUTH_TOKEN,
          commonServiceToken
        );
        storage.setLocalStorage(keys.STORAGE_PROFILE_KEY, user);
        return {
          isAuthenticated: true,
          token: token,
          profile: user,
          commonServiceToken: commonServiceToken,
        };
      }
      return response;
    })
    .catch((error) => {
      storage.deleteLocalStorage(keys.COMMON_SERVICE_AUTH_TOKEN);
      storage.deleteLocalStorage(keys.STORAGE_TOKEN_KEY);
      storage.deleteLocalStorage(keys.STORAGE_PROFILE_KEY);
      const errorsArray = error?.receiveObj?.response?.data?.errors;
      if (errorsArray !== null && errorsArray?.Name) {
        notificationService.sendErrorMessage(errorsArray.Name[0]);
      } else if (errorsArray !== null && errorsArray?.Password) {
        notificationService.sendErrorMessage(errorsArray.Password[0]);
      } else {
        const errorMessage = error?.receiveObj?.response?.data?.message;
        if (errorMessage) {
          notificationService.sendErrorMessage(errorMessage);
        }
      }
      return error;
    });
};
