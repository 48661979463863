import create from "zustand";
import { devtools } from "zustand/middleware";

const userStore = create(
  devtools((set) => ({
    auth: {
      isAuthenticated: false,
      token: null,
      profile: null,
      commonServiceToken: null,
    },

    setAuth: (auth) => set({ auth }),
  }))
);

export default userStore;
