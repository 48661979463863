import create from "zustand";
import { devtools } from "zustand/middleware";

const loaderStore = create(
  devtools((set) => ({
    loader: false,
    setLoader: (loader) => set({ loader }),
  }))
);

export default loaderStore;
