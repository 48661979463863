import React, { useEffect } from "react";
import useStore from "../store";
import * as constant from "../utils/constant";
import * as storage from "../services/storage";
import * as keys from "../utils/constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const RedirectedLogin = () => {
  const setAuth = useStore.userStore((state) => state.setAuth);
  const setLoader = useStore.loaderStore((state) => state.setLoader);
  const navigate = useNavigate();
  useEffect(() => {
    setLoader(true);
    let redirectedUsername = getCookie("_username");
    let token = getCookie(constant.STORAGE_TOKEN_KEY);
    token = JSON.parse(token).token;
    deleteAllCookies();
    if (token) {
      axios
        .post(
          process.env.REACT_APP_API_URL + "User/GetUserProfile/",
          { name: redirectedUsername, password: "" },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":
                process.env.REACT_APP_ACCESS_ORIGIN,
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const token = response.data.data.token;
          const user = response.data.data;
          const commonServiceToken = response.data.data?.commonServiceToken;
          storage.setLocalStorage(keys.STORAGE_TOKEN_KEY, token);
          storage.setLocalStorage(
            keys.COMMON_SERVICE_AUTH_TOKEN,
            commonServiceToken
          );
          storage.setLocalStorage(keys.STORAGE_PROFILE_KEY, user);
          setAuth({
            isAuthenticated: true,
            token: token,
            profile: user,
            commonServiceToken: commonServiceToken,
          });
          navigate("/Patient Report/report/page/1");
        })
        .catch((error) => {
          toast.error("Some error occurred, please login.");
          navigate("/login");
        })
        .finally(() => {
          setLoader(false);
        });
    }
    //eslint-disable-next-line
  }, []);
  return <div></div>;
};
