import userStore from "./stores/auth";
import loaderStore from "./stores/loader";
import activePageStore from "./stores/activePage";
import tablePageLength from "./stores/tablePageLength";

const globalStore = {
  userStore,
  loaderStore,
  activePageStore,
  tablePageLength,
};

export default globalStore;
