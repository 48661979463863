import axios from "axios";
import * as storage from "../services/storage";
import * as constant from "../utils/constant";
import { show, hide } from "./loader.service";

let requestCount = 0;
export default axios.create({});

const includeToken = (config = {}) => {
  return config.hasOwnProperty("tokenEnabled") && !config.tokenEnabled
    ? false
    : true;
};

const requestHandler = (request) => {
  if (includeToken(request)) {
    const token = storage.getLocalStorage(constant.STORAGE_TOKEN_KEY);
    const AccessToken = token?.token;
    if (token) {
      request.headers["Authorization"] = `Bearer ${AccessToken}`;
    }
  }

  if (!requestCount) {
    show();
  }

  requestCount++;

  return request;
};

const requestError = (error) => {
  return Promise.reject(error);
};

const successHandler = (response) => {
  if (!requestCount) {
    return response;
  }

  requestCount--;

  if (!requestCount) {
    hide();
  }

  return response;
};

const errorHandler = (error) => {
  if (!requestCount) {
  } else {
    requestCount--;
    if (!requestCount) {
      hide();
    }
  }
  onError(error);
  return Promise.reject(error);
};

const onError = (error) => {
  let message =
    error.response &&
    error.response.data &&
    error.response.data.error_description;

  if (!message) {
    message = error.message;
  }

  if (!message) {
    message = error;
  }
};

axios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestError(error)
);

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
