/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { login } from "../../services/http/login";
import logo from "../../assets/images/brand/logo.png";
import { changeClass, removeClass } from "../../utils/utility";
import useStore from "../../store";
import { Link } from "react-router-dom";
import { useScrollLock } from "../../utils/scrollLock/scrollLock";

const Login = () => {
  const { lockScroll, unlockScroll } = useScrollLock();
  const setAuth = useStore.userStore((state) => state.setAuth);
  const setLoader = useStore.loaderStore((state) => state.setLoader);
  const [formData, SetFormData] = useState({ showPassword: false });
  const [showPassword, handleShowPassword] = useState(false);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    SetFormData({ ...formData, [name]: value });
  };

  const showPasswordFunction = () => {
    handleShowPassword(!showPassword);
  };

  useEffect(() => {
    lockScroll();
    document.getElementById("username").focus();
    changeClass("login-img");
    return () => {
      removeClass("login-img");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    if (formData?.name === "") {
      return true;
    }
    if (formData?.password === "") {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await login(formData)
      .then((resp) => {
        console.log(resp);
        setAuth(resp);
        setLoader(false);
        unlockScroll();
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const forgotPasswordTab=()=> {
    window.open("https://www.greenyourbills.com/Bill_Sys_ForgotPassword.aspx");
  }

  return (
    <>
      <div className="page">
        <div className="">
          <div className="container-login100">
            <div
              className="wrap-login100 p-6 md:tw-w-1/2 sm:tw-w-1/2 lg:tw-w-1/4"
              style={{ minWidth: "265px" }}
            >
              <form
                className="login100-form validate-form"
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="tw-flex tw-items-center tw-justify-center">
                    <img src={logo} alt="" />
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-center">
                    <h4 className="cursFont">Provider Portal</h4>
                  </div>
                  <div className="panel panel-primary">
                    <div className="panel-body tabs-menu-body p-0 pt-5">
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab5">
                          <div
                            className="wrap-input100 validate-input input-group"
                            data-bs-validate="Valid email is required: ex@abc.xyz"
                          >
                            <a
                              style={{ paddingInline: "14px" }}
                              className="input-group-text bg-white text-muted"
                            >
                              <i
                                className="zmdi zmdi-account text-muted"
                                aria-hidden="true"
                              />
                            </a>
                            <input
                              id="username"
                              onChange={(e) => handleTextChange(e)}
                              className="input100 border-start-0"
                              type="text"
                              name="name"
                              placeholder="Username"
                            />
                          </div>
                          <div
                            className="wrap-input100 validate-input input-group"
                            id="Password-toggle"
                          >
                            <a
                              onClick={() => showPasswordFunction()}
                              className="input-group-text bg-white text-muted"
                            >
                              <i
                                className={
                                  showPassword
                                    ? "zmdi  zmdi-eye-off text-muted"
                                    : "zmdi zmdi-eye text-muted"
                                }
                                aria-hidden="true"
                              />
                            </a>
                            <input
                              onChange={(e) => handleTextChange(e)}
                              name="password"
                              className="input100 border-start-0"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                            />
                          </div>
                          <div className="container-login100-form-btn">
                            <button
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                              disabled={validateForm() ? true : false}
                              to=""
                              className="loginButtonClassName btn btn-primary float-right mr-1"
                            >
                              Login
                            </button>
                          </div>
                          <div className="mt-2">
                          <Link  onClick={forgotPasswordTab}>Forgot Password</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* CONTAINER CLOSED */}
        </div>
      </div>
    </>
  );
};

export default Login;
